<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent
      title="Fundamental Principles of Explosives Safety: Understanding the Nature of Explosives"
    />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <Paragraph
          ><p>
            Understanding the nature of energetic material is crucial when
            storing, handling, processing, or transporting these types of
            materials. We can only fully understand the nature of explosives
            through appropriate testing. There are specific tests required to be
            performed on a substance or article in order to understand and
            classify an explosive. The idea of these tests is to put the
            substance or the article through a set of challenging environments
            in order to determine the sensitivity to certain stimuli.<br />
            .<br />
            These series of tests can generally be placed into two subcategories
            of sensitivity and reactivity testing. Sensitivity testing can be
            used to understand the nature of an explosive under normal
            transportation and working environments. Some of these sensitivity
            tests include electrostatic discharge testing (ESD), friction,
            impact and thermal stability testing. These tests help asses if the
            nature of the substance itself is hazardous to normal transportation
            and shipping regulations. Generally, if a test fails a thermal
            stability test then transportation is forbidden.
          </p>
          <p>
            The second set of tests are reactivity testing. These tests can
            include tests like critical height testing, TNT equivalency testing,
            propagation testing or #8 cap test. These tests see how an explosive
            will react when surrounded or packaged with similar explosives.
            Depending on how these explosives react with one another is how
            entities like DoT classify the explosives. In addition, in-process
            classification and testing is integral to the safe design of an
            operation. These tests allow companies to identify and test within
            to determine hazardous situations.
          </p>
          <p>
            Once completed, the data from these tests is to be analyzed and
            processed. Some ways in which Safety Management Services analyses
            include Threshold Initiation Level (TIL) and PROBIT analysis. The
            TIL is defined as the level above where there are no reactions after
            20 trails. That is why it is considered a threshold level, because
            it is at the threshold of where reactivity occurs. The PROBIT method
            of analysis is a statistical evaluation of the testing data using
            the TIL, as well as the next four higher levels of testing
            performed. This uses 10 trials and provides some prediction of how a
            substance may react at a certain stimulus level. It is a powerful
            tool that can be used when performing quantitative analysis to
            determine the probability of an incident.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <img src="@/assets/Resources/Blog/PlaceImpact-min.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Understanding the nature of explosives",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "Explosives can only be classified through proper sensitivity and reactivity testing. Sensitivity tests help us understand the nature of the material under normal transportation and working environments. Reactivity testing assesses how the material will react when surround by or packaged with similar material."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.about {
}
</style>
